import React from 'react'
import Insta from '../images/instagram logo.png'
import linkedIn from '../images/linkedIn logo.png'
import github from '../images/github logo.png'
import twitter from '../images/twitter logo.png'

export default function Contact() {
  return (
    <div className="section">
      <div className="df-col" style={{gap:'4.563rem'}}>
        <div className="df" id='gmailHolder'>
          <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="gmaillogo">
              <path id="gmailBG" d="M125.59 83.1024C127.224 79.9992 128.16 76.4712 128.16 72.72C128.16 63.1858 122.171 55.0699 113.759 51.8717C113.759 51.8616 113.76 51.8515 113.76 51.84C113.76 35.1389 100.221 21.6 83.52 21.6C69.431 21.6 57.6273 31.2494 54.2692 44.2901C52.1438 43.5917 49.8787 43.2 47.52 43.2C36.4435 43.2 27.3268 51.5419 26.0784 62.2829C18.5846 63.8683 12.96 70.5154 12.96 78.48C12.96 87.6255 20.3745 95.04 29.52 95.04C29.7748 95.04 30.0268 95.0227 30.2774 95.0083C30.2716 95.2603 30.24 95.5066 30.24 95.76C30.24 112.064 43.4563 125.28 59.76 125.28C69.0292 125.28 77.292 121 82.7049 114.316C85.8412 117.52 90.2016 119.52 95.04 119.52C101.736 119.52 107.531 115.704 110.399 110.134C111.914 110.616 113.525 110.88 115.2 110.88C123.948 110.88 131.04 103.788 131.04 95.04C131.04 90.2722 128.923 86.0069 125.59 83.1024Z" fill="#C7EDE6" />
              <g id="cloudLeft" style={{animation: 'gmailAnim 3s infinite'}}>
                <path id="Vector" d="M31.5001 84.96V84.24H10.0801C6.50746 84.24 3.6001 81.3326 3.6001 77.76C3.6001 74.2723 6.3289 71.4298 9.81082 71.2858L10.7857 71.2469L11.1111 70.3253C11.8196 68.3122 13.719 66.96 15.8401 66.96C16.4305 66.96 17.0094 67.0637 17.5595 67.2696L19.2716 67.9061L19.4905 66.0917C19.9268 62.4816 22.9998 59.76 26.6387 59.76C29.5863 59.76 32.2777 61.6147 33.3332 64.3766L33.9639 66.024L35.4515 65.0779C36.4782 64.4242 37.6633 64.08 38.8801 64.08C42.339 64.08 45.183 66.7858 45.3515 70.2374L45.4019 71.2757L46.4027 71.5536C49.1804 72.3283 48.2401 74.88 48.2401 77.76C48.2401 81.3326 48.2127 84.24 44.6401 84.24H35.8201V84.96H31.5001Z" fill="#FDFCEF" />
                <path id="Vector_2" d="M35.8201 84.24C35.8201 84.24 41.0617 84.24 44.6401 84.24C48.2185 84.24 46.0801 81.3384 46.0801 77.76C46.0801 74.4005 48.554 71.6702 45.2809 71.3448C45.3126 71.0842 45.3601 70.8293 45.3601 70.56C45.3601 66.9816 42.4585 64.08 38.8801 64.08C36.8094 64.08 34.9878 65.0678 33.8012 66.5798C33.5996 62.784 30.4863 59.76 26.6401 59.76C22.6643 59.76 19.4401 62.9842 19.4401 66.96C19.4401 67.6022 19.551 68.2128 19.7094 68.8061C18.7849 67.6872 17.4039 66.96 15.8401 66.96C13.2755 66.96 11.1831 68.8824 10.8649 71.3592C10.6043 71.3275 10.3494 71.28 10.0801 71.28C6.5017 71.28 3.6001 74.1816 3.6001 77.76C3.6001 81.3384 6.5017 84.24 10.0801 84.24C13.6585 84.24 23.7601 84.24 23.7601 84.24H31.5001V84.96H35.8201V84.24Z" fill="#FDFCEF" />
                <path id="Vector_3" d="M35.8199 84.96C35.4239 84.96 35.0999 84.636 35.0999 84.24C35.0999 83.844 35.4239 83.52 35.8199 83.52H44.6399C47.8165 83.52 47.5199 80.9366 47.5199 77.76C47.5199 74.8051 48.1664 72.3542 45.2072 72.0605C45.02 72.0418 44.8386 71.9438 44.7205 71.7955C44.601 71.6458 44.5434 71.4456 44.5679 71.2584L44.5952 71.0539C44.6255 70.8322 44.6399 70.6982 44.6399 70.56C44.6399 67.3834 42.0565 64.8 38.8799 64.8C37.1231 64.8 35.4786 65.6093 34.3684 67.0234C34.233 67.1976 34.0184 67.3013 33.8024 67.3013C33.7319 67.3013 33.657 67.2898 33.585 67.2667C33.3056 67.1789 33.0983 66.9139 33.081 66.6216C32.8996 63.1786 30.0685 60.48 26.6399 60.48C23.0672 60.48 20.1599 63.3874 20.1599 66.96C20.1599 67.4683 20.2376 67.9954 20.4032 68.6218C20.4882 68.94 20.3312 69.2957 20.0389 69.4426C19.9338 69.4987 19.8172 69.5261 19.7092 69.5261C19.496 69.5261 19.293 69.431 19.1548 69.264C18.3224 68.2574 17.1128 67.68 15.8399 67.68C13.6856 67.68 11.8525 69.3014 11.5789 71.4499C11.5328 71.8056 11.2261 72.0792 10.8661 72.0792L10.5565 72.0432C10.352 72.0144 10.2181 72 10.0799 72C6.90324 72 4.31988 74.5834 4.31988 77.76C4.31988 80.9366 6.90324 83.52 10.0799 83.52H31.4999C31.8959 83.52 32.2199 83.844 32.2199 84.24C32.2199 84.636 31.8959 84.96 31.4999 84.96H10.0799C6.1098 84.96 2.87988 81.7301 2.87988 77.76C2.87988 74.0318 5.67492 70.9502 9.38292 70.5917L10.234 70.5096L10.568 69.7248C11.4752 67.608 13.5431 66.24 15.8399 66.24C16.2848 66.24 16.7284 66.2918 17.1589 66.3955L18.5903 66.7411L18.9042 65.3011C19.6919 61.6738 22.9448 59.04 26.6399 59.04C29.6826 59.04 32.3984 60.7392 33.729 63.4723L34.3684 64.787L35.673 64.1275C36.6666 63.6264 37.774 63.36 38.8799 63.36C42.5677 63.36 45.6436 66.1205 46.0381 69.781L46.1346 70.6896L47 70.9877C49.8944 71.9856 48.9599 74.7072 48.9599 77.76C48.9599 81.7301 48.61 84.96 44.6399 84.96H35.8199Z" fill="#472B29" />
                <path id="Vector_4" d="M26.6399 59.04C22.2724 59.04 18.7199 62.5925 18.7199 66.96C18.7199 66.983 18.7199 67.0046 18.7199 67.0277C17.853 66.5165 16.8623 66.24 15.8399 66.24C13.2032 66.24 10.928 68.0558 10.2844 70.5672C10.2167 70.5629 10.149 70.56 10.0799 70.56C6.1098 70.56 2.87988 73.7899 2.87988 77.76C2.87988 81.7301 6.1098 84.96 10.0799 84.96H31.4999C31.8973 84.96 32.2199 84.6374 32.2199 84.24C32.2199 83.8426 31.8973 83.52 31.4999 83.52H10.0799C6.90324 83.52 4.31988 80.9366 4.31988 77.76C4.31988 74.5834 6.90324 72 10.0799 72C10.2484 72 10.4111 72.0245 10.5738 72.0461L10.7768 72.0734C10.8071 72.0778 10.8359 72.0792 10.8661 72.0792C11.2204 72.0792 11.5314 71.8128 11.5789 71.4499C11.854 69.3 13.6856 67.68 15.8399 67.68C17.1143 67.68 18.321 68.2574 19.1533 69.264C19.2944 69.4339 19.5004 69.5261 19.7092 69.5261C19.8215 69.5261 19.9338 69.5002 20.0375 69.4454C20.3384 69.2914 20.4896 68.9472 20.4032 68.6203C20.2376 67.9939 20.1599 67.4669 20.1599 66.96C20.1599 63.3874 23.0672 60.48 26.6399 60.48C30.0685 60.48 32.8996 63.1757 33.0824 66.6187C33.0983 66.9182 33.2984 67.176 33.585 67.2667C33.657 67.2898 33.7304 67.3013 33.8039 67.3013C34.0199 67.3013 34.2301 67.2019 34.3684 67.0248C35.4786 65.6107 37.1216 64.8 38.8799 64.8C42.0565 64.8 44.6399 67.3834 44.6399 70.56C44.6399 70.7285 44.6154 70.8912 44.5938 71.0539L44.5664 71.257C44.5434 71.4499 44.5981 71.6429 44.7191 71.7941C44.84 71.9453 45.0172 72.0418 45.2087 72.0605C48.1693 72.3557 47.5199 74.8066 47.5199 77.76C47.5199 80.9366 47.8165 83.52 44.6399 83.52H35.8199C35.4224 83.52 35.0999 83.8426 35.0999 84.24C35.0999 84.6374 35.4224 84.96 35.8199 84.96H44.6399C48.61 84.96 48.9599 81.7301 48.9599 77.76C48.9599 74.3083 49.4077 71.4053 46.0756 70.7371C46.0784 70.6781 46.0799 70.619 46.0799 70.56C46.0799 66.5899 42.85 63.36 38.8799 63.36C37.1864 63.36 35.578 63.959 34.2964 65.0362C33.4352 61.5744 30.3191 59.04 26.6399 59.04Z" fill="#472B29" />
                <path id="Vector_5" d="M24.4799 69.12C22.4595 69.12 20.7229 70.5586 20.2851 72.491C19.7567 72.1714 19.1533 72 18.5399 72C16.809 72 15.3747 73.2917 15.1515 74.9779C14.8592 74.9117 14.5813 74.88 14.3091 74.88C12.1074 74.88 10.2613 76.608 10.1072 78.8141C10.0943 79.0128 10.244 79.1856 10.4413 79.2C10.4499 79.2 10.4586 79.2 10.4672 79.2C10.6544 79.2 10.8128 79.0546 10.8258 78.8659C10.9539 77.0357 12.4832 75.6 14.3091 75.6C14.6389 75.6 14.9859 75.6605 15.3719 75.7829C15.4035 75.793 15.4367 75.7973 15.4683 75.7973C15.5475 75.7973 15.6267 75.7685 15.693 75.7195C15.7851 75.6533 15.8399 75.5352 15.8399 75.42C15.8399 73.931 17.0509 72.72 18.5399 72.72C19.1835 72.72 19.8143 72.9619 20.3125 73.4011C20.3802 73.4602 20.4651 73.4918 20.5501 73.4918C20.5962 73.4918 20.6408 73.4832 20.684 73.4645C20.8107 73.4141 20.8971 73.296 20.9072 73.1592C21.057 71.2973 22.6251 69.84 24.4799 69.84C24.7794 69.84 25.0919 69.889 25.4619 69.9941C25.4951 70.0042 25.5296 70.0085 25.5627 70.0085C25.7197 70.0085 25.8608 69.9034 25.9069 69.7464C25.9616 69.5549 25.8507 69.3562 25.6592 69.3014C25.2243 69.1776 24.8485 69.12 24.4799 69.12Z" fill="#472B29" />
              </g>
              <g id="dashesTop">
                <path id="Vector_6" d="M101.383 34.56H87.1199C86.7225 34.56 86.3999 34.2374 86.3999 33.84C86.3999 33.4426 86.7225 33.12 87.1199 33.12H101.383C101.781 33.12 102.103 33.4426 102.103 33.84C102.103 34.2374 101.782 34.56 101.383 34.56Z" fill="white" />
                <path id="Vector_7" d="M105.84 34.56H104.4C104.002 34.56 103.68 34.2374 103.68 33.84C103.68 33.4426 104.002 33.12 104.4 33.12H105.84C106.237 33.12 106.56 33.4426 106.56 33.84C106.56 34.2374 106.239 34.56 105.84 34.56Z" fill="white" />
                <path id="Vector_8" d="M113.027 37.44H100.08C99.6824 37.44 99.3599 37.1174 99.3599 36.72C99.3599 36.3226 99.6824 36 100.08 36H113.027C113.424 36 113.747 36.3226 113.747 36.72C113.747 37.1174 113.424 37.44 113.027 37.44Z" fill="white" />
                <path id="Vector_9" d="M97.2 37.44H95.76C95.3626 37.44 95.04 37.1174 95.04 36.72C95.04 36.3226 95.3626 36 95.76 36H97.2C97.5975 36 97.92 36.3226 97.92 36.72C97.92 37.1174 97.5989 37.44 97.2 37.44Z" fill="white" />
                <path id="Vector_10" d="M92.8798 37.44H89.9998C89.6023 37.44 89.2798 37.1174 89.2798 36.72C89.2798 36.3226 89.6023 36 89.9998 36H92.8798C93.2772 36 93.5998 36.3226 93.5998 36.72C93.5998 37.1174 93.2787 37.44 92.8798 37.44Z" fill="white" />
                <path id="Vector_11" d="M101.52 40.32H98.6399C98.2425 40.32 97.9199 39.9974 97.9199 39.6C97.9199 39.2026 98.2425 38.88 98.6399 38.88H101.52C101.917 38.88 102.24 39.2026 102.24 39.6C102.24 39.9974 101.917 40.32 101.52 40.32Z" fill="white" />
                <path id="Vector_12" d="M105.84 27.36C105.585 27.36 104.655 27.36 104.4 27.36C104.002 27.36 103.68 27.6826 103.68 28.08C103.68 28.4774 104.002 28.8 104.4 28.8C104.655 28.8 105.585 28.8 105.84 28.8C106.237 28.8 106.56 28.4774 106.56 28.08C106.56 27.6826 106.237 27.36 105.84 27.36Z" fill="white" />
                <path id="Vector_13" d="M105.84 30.24C105.585 30.24 98.8948 30.24 98.6399 30.24C98.2425 30.24 97.9199 30.5626 97.9199 30.96C97.9199 31.3574 98.2425 31.68 98.6399 31.68C98.8948 31.68 105.585 31.68 105.84 31.68C106.237 31.68 106.56 31.3574 106.56 30.96C106.56 30.5626 106.237 30.24 105.84 30.24Z" fill="white" />
                <path id="Vector_14" d="M113.04 33.12C112.785 33.12 108.975 33.12 108.72 33.12C108.323 33.12 108 33.4426 108 33.84C108 34.2374 108.323 34.56 108.72 34.56C108.975 34.56 112.785 34.56 113.04 34.56C113.437 34.56 113.76 34.2374 113.76 33.84C113.76 33.4426 113.437 33.12 113.04 33.12Z" fill="white" />
              </g>
              <g id="dashesBot">
                <path id="Vector_15" d="M46.8002 116.64H32.4002C32.0027 116.64 31.6802 116.317 31.6802 115.92C31.6802 115.523 32.0027 115.2 32.4002 115.2H46.8002C47.1976 115.2 47.5202 115.523 47.5202 115.92C47.5202 116.317 47.1976 116.64 46.8002 116.64Z" fill="white" />
                <path id="Vector_16" d="M52.56 116.64H49.68C49.2825 116.64 48.96 116.317 48.96 115.92C48.96 115.523 49.2825 115.2 49.68 115.2H52.56C52.9574 115.2 53.28 115.523 53.28 115.92C53.28 116.317 52.9574 116.64 52.56 116.64Z" fill="white" />
                <path id="Vector_17" d="M59.7601 119.52H45.3601C44.9627 119.52 44.6401 119.197 44.6401 118.8C44.6401 118.403 44.9627 118.08 45.3601 118.08H59.7601C60.1576 118.08 60.4801 118.403 60.4801 118.8C60.4801 119.197 60.159 119.52 59.7601 119.52Z" fill="white" />
                <path id="Vector_18" d="M42.4803 119.52H41.0403C40.6429 119.52 40.3203 119.197 40.3203 118.8C40.3203 118.403 40.6429 118.08 41.0403 118.08H42.4803C42.8778 118.08 43.2003 118.403 43.2003 118.8C43.2003 119.197 42.8778 119.52 42.4803 119.52Z" fill="white" />
                <path id="Vector_19" d="M37.9801 119.52H35.2801C34.8826 119.52 34.5601 119.197 34.5601 118.8C34.5601 118.403 34.8826 118.08 35.2801 118.08H37.9801C38.3775 118.08 38.7001 118.403 38.7001 118.8C38.7001 119.197 38.3775 119.52 37.9801 119.52Z" fill="white" />
                <path id="Vector_20" d="M51.1202 113.76H43.9202C43.5228 113.76 43.2002 113.437 43.2002 113.04C43.2002 112.643 43.5228 112.32 43.9202 112.32H51.1202C51.5176 112.32 51.8402 112.643 51.8402 113.04C51.8402 113.437 51.5191 113.76 51.1202 113.76Z" fill="white" />
                <path id="Vector_21" d="M46.8002 122.4H43.9202C43.5228 122.4 43.2002 122.077 43.2002 121.68C43.2002 121.283 43.5228 120.96 43.9202 120.96H46.8002C47.1976 120.96 47.5202 121.283 47.5202 121.68C47.5202 122.077 47.1976 122.4 46.8002 122.4Z" fill="white" />
              </g>
              <g id="MailIcon">
                <path id="Vector_22" d="M57.2762 72.4536V100.976H45.9909C43.6495 100.976 41.7588 99.085 41.7588 96.7435V60.323L57.2762 72.4536Z" fill="#78A0CF" />
                <path id="Vector_23" d="M57.2763 52.7616V72.4536L41.7603 60.3231V56.5431C41.7603 54.7373 42.3809 53.0727 43.4105 51.7608C45.9651 48.4416 50.982 47.7749 54.3012 50.4346L57.2763 52.7616Z" fill="#C4211F" />
                <path id="Vector_24" d="M95.3624 52.7616V72.4392L76.3199 87.3216L57.2759 72.4536V52.7616L76.3199 67.6296L95.3624 52.7616Z" fill="#EB4235" />
                <path id="Vector_25" d="M110.88 56.5416V60.3216L95.3643 72.4392V52.7616L98.3407 50.4346C99.7663 49.32 101.444 48.7843 103.123 48.7843C107.298 48.744 110.928 52.2907 110.88 56.5416Z" fill="#F1BC19" />
                <path id="Vector_26" d="M110.88 60.323V96.745C110.88 99.0864 108.99 100.977 106.648 100.977H95.3628V72.4392L110.88 60.323Z" fill="#9CA74C" />
                <path id="Vector_27" d="M45.9922 93.9226V96.745" stroke="#472B29" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_28" d="M45.9922 85.4597V89.6918" stroke="#472B29" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_29" d="M45.9922 71.3534V81.1483" stroke="#472B29" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_30" d="M57.2762 53.0885V100.977H45.9909C43.6538 100.977 41.7588 99.0821 41.7588 96.745V56.5416C41.7588 52.2562 45.2321 48.7829 49.5175 48.7829" stroke="#472B29" strokeWidth="1.4" strokeMiterlimit="10" strokeLinejoin="round" />
                <path id="Vector_31" d="M109.236 51.7651C106.598 48.3883 101.722 47.7907 98.3451 50.4274L76.3203 67.6368L54.2955 50.4288C50.9187 47.7907 46.0429 48.3898 43.4048 51.7666" stroke="#472B29" strokeWidth="1.4" strokeMiterlimit="10" strokeLinejoin="round" />
                <path id="Vector_32" d="M78.2729 80.4125L84.6867 75.3379" stroke="#472B29" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_33" d="M70.3037 77.197L75.2962 81.1483" stroke="#472B29" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_34" d="M64.7783 72.8251L68.0903 75.4459" stroke="#472B29" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_35" d="M61.1367 69.9422L62.5652 71.0741" stroke="#472B29" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_36" d="M41.9868 60.5016L76.3207 87.3274L110.63 60.5218" stroke="#472B29" strokeWidth="1.4" strokeMiterlimit="10" strokeLinejoin="round" />
                <path id="Vector_37" d="M103.122 48.7843C107.407 48.7843 110.88 52.2576 110.88 56.543V96.745C110.88 99.0821 108.985 100.977 106.648 100.977H95.3628V52.992" stroke="#472B29" strokeWidth="1.4" strokeMiterlimit="10" strokeLinejoin="round" />
              </g>
              <g id="cloudRight" style={{animation: 'gmailAnim 3s infinite 1s'}}>
                <path id="Vector_38" d="M121.68 95.04V94.32H110.88C108.101 94.32 105.84 92.0592 105.84 89.28C105.84 86.5512 107.974 84.3394 110.7 84.2429L111.577 84.2126L111.952 83.4192C112.541 82.1693 113.816 81.36 115.2 81.36C115.405 81.36 115.604 81.3773 115.801 81.4104L117.198 81.6581L117.465 80.2656C117.987 77.5613 120.366 75.6 123.122 75.6C125.192 75.6 127.115 76.7275 128.14 78.5448L128.839 79.7818L130.086 79.0978C130.826 78.6931 131.652 78.4786 132.48 78.4786C135.167 78.4786 137.379 80.5795 137.513 83.2608L137.562 84.227L138.474 84.5482C140.488 85.2581 141.84 87.1589 141.84 89.28C141.84 92.0592 139.58 94.32 136.8 94.32H131.76V95.04H121.68Z" fill="#FDFCEF" />
                <path id="Vector_39" d="M131.76 94.32C131.76 94.32 134.017 94.32 136.8 94.32C139.584 94.32 141.84 92.0635 141.84 89.28C141.84 86.7154 139.918 84.623 137.441 84.3048C137.481 84.047 137.52 83.7893 137.52 83.52C137.52 80.7365 135.264 78.48 132.48 78.48C130.994 78.48 129.672 79.1352 128.751 80.1576C128.198 77.5541 125.889 75.6 123.12 75.6C119.939 75.6 117.36 78.179 117.36 81.36C117.36 81.635 117.404 81.8986 117.441 82.1635C116.825 81.6682 116.053 81.36 115.2 81.36C113.432 81.36 111.968 82.6373 111.665 84.3192C111.407 84.2789 111.15 84.24 110.88 84.24C108.097 84.24 105.84 86.4965 105.84 89.28C105.84 92.0635 108.097 94.32 110.88 94.32C113.664 94.32 121.68 94.32 121.68 94.32V95.04H131.76V94.32Z" fill="#FDFCEF" />
                <path id="Vector_40" d="M134.28 87.84C134.081 87.84 133.92 87.6787 133.92 87.48C133.92 85.7189 135.353 84.2861 137.114 84.2861C137.163 84.299 138.175 84.2846 138.905 84.4819C139.097 84.5338 139.21 84.731 139.159 84.9226C139.107 85.1155 138.91 85.2293 138.718 85.176C138.079 85.0032 137.134 85.0032 137.115 85.0061C135.75 85.0061 134.64 86.1163 134.64 87.48C134.64 87.6787 134.479 87.84 134.28 87.84Z" fill="#472B29" />
                <path id="Vector_41" d="M124.56 95.04C124.958 95.04 125.28 94.7176 125.28 94.32C125.28 93.9224 124.958 93.6 124.56 93.6C124.163 93.6 123.84 93.9224 123.84 94.32C123.84 94.7176 124.163 95.04 124.56 95.04Z" fill="#472B29" />
                <path id="Vector_42" d="M131.76 95.04C131.364 95.04 131.04 94.716 131.04 94.32C131.04 93.924 131.364 93.6 131.76 93.6H136.8C139.182 93.6 141.12 91.6618 141.12 89.28C141.12 87.1258 139.499 85.2926 137.35 85.019C137.163 84.9946 136.98 84.8894 136.868 84.7382C136.75 84.5827 136.701 84.3811 136.731 84.1925C136.777 83.8915 136.8 83.7086 136.8 83.52C136.8 81.1382 134.862 79.2 132.48 79.2C131.256 79.2 130.121 79.7112 129.285 80.6414C129.148 80.7912 128.954 80.879 128.749 80.879C128.689 80.879 128.627 80.8704 128.566 80.8531C128.297 80.7797 128.103 80.5709 128.046 80.3059C127.555 77.9962 125.485 76.32 123.12 76.32C120.341 76.32 118.08 78.5808 118.08 81.36C118.08 81.5342 118.1 81.7027 118.126 81.8698C118.182 82.2614 118.044 82.5941 117.791 82.7395C117.655 82.8158 117.513 82.8562 117.375 82.8562C117.226 82.8562 117.102 82.8144 116.991 82.7251C116.463 82.3032 115.844 82.08 115.2 82.08C113.808 82.08 112.618 83.075 112.373 84.4488C112.311 84.7901 112.015 85.0392 111.666 85.0392L111.498 85.0234C111.25 84.983 111.067 84.96 110.88 84.96C108.498 84.96 106.56 86.8982 106.56 89.28C106.56 91.6618 108.498 93.6 110.88 93.6H121.68C122.076 93.6 122.4 93.924 122.4 94.32C122.4 94.716 122.076 95.04 121.68 95.04H110.88C107.703 95.04 105.12 92.4566 105.12 89.28C105.12 86.341 107.41 83.8224 110.333 83.5459L111.08 83.4754L111.452 82.8245C112.215 81.4766 113.652 80.64 115.2 80.64L116.571 80.6947L116.882 79.5989C117.671 76.8211 120.236 74.88 123.12 74.88C125.267 74.88 127.269 75.9398 128.474 77.7154L129.141 78.7003L130.234 78.2309C130.949 77.9213 131.726 77.76 132.48 77.76C135.403 77.76 137.863 79.9488 138.203 82.849L138.299 83.6784L139.068 84.0067C141.189 84.911 142.56 86.9803 142.56 89.28C142.56 92.4566 139.977 95.04 136.8 95.04H131.76Z" fill="#472B29" />
                <path id="Vector_43" d="M136.8 95.04H131.76C131.363 95.04 131.04 94.7174 131.04 94.32C131.04 93.9226 131.363 93.6 131.76 93.6H136.8C139.182 93.6 141.12 91.6618 141.12 89.28C141.12 87.1258 139.5 85.2941 137.35 85.019C137.157 84.9931 136.983 84.8923 136.866 84.7368C136.75 84.5813 136.701 84.3854 136.731 84.1939C136.764 83.9722 136.8 83.7504 136.8 83.52C136.8 81.1382 134.862 79.2 132.48 79.2C131.256 79.2 130.121 79.7112 129.285 80.64C129.105 80.8402 128.823 80.9251 128.565 80.8531C128.303 80.7826 128.103 80.5723 128.046 80.3074C127.555 77.9976 125.483 76.32 123.12 76.32C120.341 76.32 118.08 78.5808 118.08 81.36C118.08 81.5659 118.11 81.7632 118.139 81.9619C118.181 82.2542 118.048 82.5926 117.791 82.7395C117.533 82.8864 117.22 82.9094 116.99 82.7251C116.464 82.3032 115.847 82.08 115.2 82.08C113.809 82.08 112.62 83.075 112.373 84.4474C112.304 84.8318 111.947 85.0896 111.554 85.0306C111.332 84.996 111.111 84.96 110.88 84.96C108.498 84.96 106.56 86.8982 106.56 89.28C106.56 91.6618 108.498 93.6 110.88 93.6H121.68C122.078 93.6 122.4 93.9226 122.4 94.32C122.4 94.7174 122.078 95.04 121.68 95.04H110.88C107.703 95.04 105.12 92.4566 105.12 89.28C105.12 86.1034 107.703 83.52 110.88 83.52C110.965 83.52 111.047 83.5229 111.131 83.5286C111.727 81.8208 113.344 80.64 115.2 80.64C115.703 80.64 116.192 80.7278 116.656 80.8992C116.894 77.5397 119.702 74.88 123.12 74.88C125.757 74.88 128.111 76.5029 129.1 78.8746C130.08 78.1574 131.263 77.76 132.48 77.76C135.657 77.76 138.24 80.3434 138.24 83.52C138.24 83.5891 138.239 83.6568 138.234 83.7245C140.744 84.3696 142.56 86.6434 142.56 89.28C142.56 92.4566 139.977 95.04 136.8 95.04Z" fill="#472B29" />
                <path id="Vector_44" d="M128.88 93.6C128.651 93.6 127.669 93.6 127.44 93.6C127.043 93.6 126.72 93.9226 126.72 94.32C126.72 94.7174 127.043 95.04 127.44 95.04C127.669 95.04 128.651 95.04 128.88 95.04C129.278 95.04 129.6 94.7174 129.6 94.32C129.6 93.9226 129.278 93.6 128.88 93.6Z" fill="#472B29" />
              </g>
            </g>
          </svg>
          <div className="df-col" style={{gap:'1.063rem'}}>
            <div className="text-white font-25-200">Mail me at : <span className="font-25-400">kprakhar01@gmail.com</span></div>
            <a href='https://mail.google.com/mail/u/0/?to=kprakhar01@gmail.com&su=Your+Subject&body=Your+Message+Here&fs=1&tf=cm' target='_blank'  rel='noreferrer' className="aboutBtn text-red font-20-400">Click here to auto fill</a>            
          </div>
        </div>
        <div className="df-col" style={{gap:'1.75rem'}}>
          <div className="font-25-200 text-white">Other Socials : </div>
          <div className="df" style={{gap:'0.625rem'}}>
            <a href='https://www.instagram.com/__prakhar.__/' target='_blank'  rel='noreferrer'><img src={Insta} alt='socialImg' className="img-50"></img></a>
            <a href='https://www.linkedin.com/in/prakhar-khandelwal-8a988528b/' target='_blank'  rel='noreferrer'><img src={linkedIn} alt='socialImg' className="img-50"></img></a>
            <a href='https://github.com/Git-Prakhar' target='_blank'  rel='noreferrer'><img src={github} alt='socialImg' className="img-50"></img></a>
            <a href='https://twitter.com/____Prakhar__' target='_blank'  rel='noreferrer'><img src={twitter} alt='socialImg' className="img-50"></img></a>
          </div>
        </div>
      </div>
    </div>
  )
}
